import axios from "axios";
import store from "@/store";
import router from "@/router";

class CommonHelper {
    static instance = null;
    static createInstance() {
        var object = new CommonHelper();
        return object;
    }

    static getInstance() {
        if (!CommonHelper.instance) {
            CommonHelper.instance = CommonHelper.createInstance();
        }
        return CommonHelper.instance;
    }

    constructor(){
    }

    delay(ms){
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    parseBool(str) {
        if (str.length == null) {
            return str == 1 ? true : false;
        } else {
            return str == "true" ? true : false;
        }
    }

    isEmptyObject(obj){
        return JSON.stringify(obj) === '{}';
    }

    millitime(){
        return Number(new Date());
    }

    str_pad(input, padLength, padString, padType) {
        // eslint-disable-line camelcase
        //  discuss at: https://locutus.io/php/str_pad/
        // original by: Kevin van Zonneveld (https://kvz.io)
        // improved by: Michael White (https://getsprink.com)
        //    input by: Marco van Oort
        // bugfixed by: Brett Zamir (https://brett-zamir.me)
        //   example 1: str_pad('Kevin van Zonneveld', 30, '-=', 'STR_PAD_LEFT')
        //   returns 1: '-=-=-=-=-=-Kevin van Zonneveld'
        //   example 2: str_pad('Kevin van Zonneveld', 30, '-', 'STR_PAD_BOTH')
        //   returns 2: '------Kevin van Zonneveld-----'
        let half = ''
        let padToGo
        const _strPadRepeater = function (s, len) {
            let collect = ''
            while (collect.length < len) {
                collect += s
            }
            collect = collect.substr(0, len)
            return collect
        }
        input += ''
        padString = padString !== undefined ? padString : ' '
        if (padType !== 'STR_PAD_LEFT' && padType !== 'STR_PAD_RIGHT' && padType !== 'STR_PAD_BOTH') {
            padType = 'STR_PAD_RIGHT'
        }
        if ((padToGo = padLength - input.length) > 0) {
            if (padType === 'STR_PAD_LEFT') {
                input = _strPadRepeater(padString, padToGo) + input
            } else if (padType === 'STR_PAD_RIGHT') {
                input = input + _strPadRepeater(padString, padToGo)
            } else if (padType === 'STR_PAD_BOTH') {
                half = _strPadRepeater(padString, Math.ceil(padToGo / 2))
                input = half + input + half
                input = input.substr(0, padLength)
            }
        }
        return input
    }

    downloadBlobFile(filename,blobData){
        try {
            var fileURL = window.URL.createObjectURL(new Blob([blobData]));
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', filename);
            document.body.appendChild(fileLink);

            fileLink.click();

        } catch (error) {
            throw Error("[CommonHelper] downloadBlobFile()::ERROR = "+error);
        }
    }

}

const instance = CommonHelper.getInstance();
export default instance;